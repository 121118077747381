import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalErrorHandlerService } from '@core/services/global-error-handler.service';
import { Store } from '@ngrx/store';
import { Observable, catchError, throwError } from 'rxjs';
import { logoutUser } from 'src/app/auth/state/actions/auth.actions';
import { unsetLoading } from 'src/app/loading/state/actions/loading.actions';
import { REDIRECT_REQUEST, SKIP_GLOBAL_ERROR_HANDLER } from './http-context-tokens';

@Injectable({
  providedIn: 'root',
})
export class GlobalRequestErrorHandlerInterceptor implements HttpInterceptor {
  private store = inject(Store);
  private router = inject(Router);
  private globalErrorHandlerService = inject(GlobalErrorHandlerService);
  private snackbar = inject(MatSnackBar);


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!req.context.get(SKIP_GLOBAL_ERROR_HANDLER)) {
          this.store.dispatch(unsetLoading());
          this.errorHandler(error, req);
          throw error;
        } else {
          console.error('[Global Error Handler] ', error);
          this.store.dispatch(unsetLoading());
          return throwError(() => error);
        }
      })
    );
  }

  // note: we cannot use TranslateService due to circular dependency
  private errorHandler(error: HttpErrorResponse, req: HttpRequest<any>) {
    this.store.dispatch(unsetLoading());
    switch (error.status) {
      case 401:
        // this action has an effect associated with it that clears user tenant
        // and redirects to the signin page
        if (this.router.url !== '/signin' && !this.router.url.startsWith('/checkout')) {
          this.snackbar.open('Session expired', 'x', { duration: 5000 });
        }
        this.store.dispatch(logoutUser());

        break;
      case 403: // FORBIDDEN
        // this.store.dispatch(handleHttpError({status: 403}));
        this.snackbar.open("You're not authorized to perform this action.", 'x', { duration: 5000 });
        this.store.dispatch(logoutUser());
        break;
      case 404: // NOT FOUND - DO NOT REMOVE!!!
        console.log('NOT FOUND MUST be handled by subsciption');
        if (req.context.get(REDIRECT_REQUEST)) {
          this.router.navigate(['not-found']);
        }
        break;
      case 409:
        this.snackbar.open("The resource you're trying to create already exists.", 'x', { duration: 50000 });
        break;
      case 417:
        // TODO: check if url is mgmt/users/
        return;
      case 429:
        this.snackbar.open("We're noticing some unusual activity on your account, please contact support.", 'x', {
          duration: 3000,
        });
        this.store.dispatch(logoutUser());
        break;
      case 0: // NO NETWORK
        this.snackbar.open('You are offline.', 'x', { duration: 5000 });
        break;
      default:
        // throw new Error('test');
        if (error) {
          this.globalErrorHandlerService.set(error);
          this.router.navigate(['error']);
        }
        break;
    }
  }
}
