import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap, take } from 'rxjs';
import { selectAuthUser } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';

/* 

  If user is a campus user, redirect to /campus/flows

*/

@Injectable({
  providedIn: 'root',
})
export class RedirectIfCampusGuard {
  private store = inject(Store);
  private router = inject(Router);


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectAuthUser).pipe(
      take(1),
      switchMap((user: User.User) => {
        if (user.tenants && user.tenants.length > 0 && user.tenants[0] === 'campus') {
          this.router.navigate(['', 'campus', 'flows']);
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}
