/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable, inject } from '@angular/core';
import { PaddleService } from '@core/services/paddle.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { Product } from '../../../core/models/paddle';
import { getPaddleProducts, setProducts } from '../actions/paddle.actions';

@Injectable()
export class PaddleEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private paddleSvc = inject(PaddleService);


  getPaddleProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaddleProducts),
      exhaustMap(() => {
        return this.paddleSvc.getProducts().pipe(
          map((resp: any) => {
            const products = resp.data as Product[];

            return setProducts({ products: { ...products } });
          })
        );
      })
    )
  );
}
