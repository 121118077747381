import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { logoutUserWithoutRedirecting } from 'src/app/auth/state/actions/auth.actions';
import { selectAuthUser } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';

/**
 * If user is already logged and tries to access pwd reset page then it is logged out
 */
@Injectable({
  providedIn: 'root',
})
export class LogoutIfAuthenticatedGuard {
  private store = inject(Store);


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectAuthUser).pipe(
      switchMap((user: User.User) => {
        if (user) {
          this.store.dispatch(logoutUserWithoutRedirecting());
          return of(true);
        } else {
          return of(true);
        }
      })
    );
  }
}
