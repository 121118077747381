import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { User } from '@core/models/users';
import { Store } from '@ngrx/store';
import { Observable, filter, map } from 'rxjs';
import { selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class DisallowNavigationToNativaGuard {
  private store = inject(Store);
  private router = inject(Router);


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectUserRoles).pipe(
      filter(Boolean),
      map(roles => {
        return roles.length === 1 && roles[0] === User.UserRole.NATIVA;
      }),
      // return false if user has only nativa role
      map(hasOnlyNativaRole => {
        if (hasOnlyNativaRole) {
          this.router.navigate(['management']);
          return false;
        }
        return true;
      })
    );
  }
}
