import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, first, map, of, switchMap } from 'rxjs';
import { selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';
import { PermissionDeniedDialogService } from '../services/permission-denied-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ExceptNativaRoleGuard {
  private store = inject(Store);
  private permissionDeniedSvc = inject(PermissionDeniedDialogService);


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectUserRoles).pipe(
      first(Boolean),
      switchMap((roles: User.UserRole[]) => {
        const hasNativaRole = roles.some(role => [User.UserRole.NATIVA].includes(role));
        return hasNativaRole ? this.permissionDeniedSvc.displayDialog().pipe(map(() => false)) : of(true);
      })
    );
  }
}
