import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { WhoisUserService } from '../../auth/services/whois-user.service';

@Injectable({
  providedIn: 'root',
})
export class HasAlreadyProvidedConsents {
  private store = inject(Store);
  private router = inject(Router);
  private wis = inject(WhoisUserService);

  canActivate(route: ActivatedRouteSnapshot,  state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const user = this.wis._user();
      if (user
          && user!.consents
          && user!.consents.serviceAndContractFulfillment
          && user!.consents.termsAndConditions
          && user!.consents.unfairTermsAndConditions) {
        console.debug('user has already provided required consents', user.consents)
        this.router.navigate(['']);
        return false;
      } else {
        return true;
      }
    }
}
