import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { resetUserFilters, updateTextSearchFilter, updateUserFilters } from '../actions/user-filters.actions';
import { updateUserCurrentPage } from './user.actions';

@Injectable()
export class UserFiltersEffects {
  private readonly actions = inject(Actions);


  onResetUserFiltersResetPagination$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
    this.actions.pipe(
      ofType(resetUserFilters),
      map(() => updateUserCurrentPage({ pageIndex: 0, pageSize: 10, length: 0 }))
    )
  );

  onUpdateTextSearchFilterResetPagination$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
    this.actions.pipe(
      ofType(updateTextSearchFilter),
      map(() => updateUserCurrentPage({ pageIndex: 0, pageSize: 10, length: 0 }))
    )
  );

  onUpdateUserFiltersResetPagination$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
    this.actions.pipe(
      ofType(updateUserFilters),
      map(() => updateUserCurrentPage({ pageIndex: 0, pageSize: 10, length: 0 }))
    )
  );
}
