import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { setLoading, unsetLoading } from 'src/app/loading/state/actions/loading.actions';

@Injectable({ providedIn: 'root' })
export class NetwordActivityInterceptor implements HttpInterceptor {
  private store = inject(Store);


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Sent) {
          this.store.dispatch(setLoading());
        } else if (event.type === HttpEventType.Response) {
          setTimeout(() => this.store.dispatch(unsetLoading()), 750);
        }

        return event;
      })
    );
  }
}
