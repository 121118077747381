import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, first, map } from 'rxjs';
import { selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';

@Injectable({
  providedIn: 'root',
})
export class HasNativaRoleGuard {
  private store = inject(Store);
  private router = inject(Router);


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectUserRoles).pipe(
      first(Boolean),
      map((userRoles: User.UserRole[]) => {
        const hasNativaRole = userRoles.some(role => [User.UserRole.NATIVA].includes(role));
        if (hasNativaRole) {
          return true;
        }
        this.router.navigate(['']);
        return false;
      })
    );
  }
}
