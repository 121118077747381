import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { selectLang } from './auth/state/selectors/auth.selectors';
import { isMobile, isNotMobile } from './core/store/layout/actions';
import { environment } from 'src/environments/environment';

const CUSTOM_BREAKPOINTS = {
  Small: '(min-width: 576px) and (max-width: 767.98px)',
  Medium: '(min-width: 768px) and (max-width: 1279.98px)',
  Large: '(min-width: 1280px)',
  XLarge: '(min-width: 1920px)',
};

@Component({
  selector: 'app-root',
  styles: [],
  template: `
    <router-outlet></router-outlet>
  `,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly store = inject(Store);
  private readonly translateSvc = inject(TranslateService);
  private readonly dr = inject(DestroyRef);

  ngOnInit(): void {
    console.log('AppComponent initializing...');
    console.log(`🚀 APP_VERSION: ${environment.APP_VERSION}`);

    // Listen for language changes from the store
    this.store
      .select(selectLang)
      .pipe(
        takeUntilDestroyed(this.dr),
        tap(lang => {
          if (lang) {
            this.translateSvc.use(lang);
          }
        })
      )
      .subscribe();

    // Observe breakpoints and dispatch NgRx actions accordingly
    this.breakpointObserver
      .observe([CUSTOM_BREAKPOINTS.Medium, CUSTOM_BREAKPOINTS.Large, CUSTOM_BREAKPOINTS.XLarge])
      .pipe(
        takeUntilDestroyed(this.dr),
        tap((value: BreakpointState) =>
          value.matches ? this.store.dispatch(isNotMobile()) : this.store.dispatch(isMobile())
        )
      )
      .subscribe();
  }

}
