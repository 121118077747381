import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DisallowNavigationToNativaGuard } from '@core/guards/disallow-navigation-to-nativa.guard';

import { AuthGuard } from '@core/guards';
import { ExceptNativaRoleGuard } from '@core/guards';
import { HasNativaRoleGuard } from '@core/guards';
import { LogoutIfAuthenticatedGuard } from '@core/guards/logout-if-authenticated.guard';
import { RedirectIfAuthenticatedGuard } from '@core/guards';
import { RedirectIfCampusGuard } from '@core/guards/redirect-if-campus-user.guard';
import { RedirectWithoutConsents } from '@core/guards/must-provide-consents.guard';
import { HasAlreadyProvidedConsents } from '@core/guards/has-provided-concents.guard';

const routes: Routes = [
  {
    path: 'signin',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        canActivate: [RedirectIfAuthenticatedGuard],
        path: '',
        loadComponent: () => import('./auth/components/signin/signin.component').then(m => m.SigninComponent),
      },
      {
        canActivate: [LogoutIfAuthenticatedGuard],
        path: 'reset',
        loadComponent: () =>
          import('./auth/components/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
      },
      {
        canActivate: [LogoutIfAuthenticatedGuard],
        path: 'activate',
        loadComponent: () =>
          import('./auth/components/activate-account/activate-account.component').then(m => m.ActivateAccountComponent),
      },
      {
        path: 'check-your-email',
        loadComponent: () =>
          import('./auth/components/check-your-email/check-your-email.component').then(m => m.CheckYourEmailComponent),
      },
    ],
  },
  {
    path: 'checkout',
    canActivate: [LogoutIfAuthenticatedGuard],
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./auth/components/checkout/checkout.component').then(m => m.CheckoutComponent),
      },
    ],
  },
  {
    path: 'signin/reset-request',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./auth/components/pwd-reset-request/pwd-reset-request').then(m => m.PwdResetRequestComponent),
      },
    ],
  },
  {
    path: 'not-found',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./core/components/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
      },
    ],
  },
  {
    path: 'error',
    loadComponent: () =>
      import('./core/components/footer-wrapper/footer-wrapper.component').then(m => m.FooterWrapperComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./core/components/app-error-page/app-error-page.component').then(m => m.AppErrorPageComponent),
      },
    ],
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@core/components/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
      },
    ],
  },
  {
    path: 'privacy-policy-en',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@core/components/privacy-policy/privacy-policy-en.component').then(m => m.PrivacyPolicyEnComponent),
      },
    ],
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@core/components/terms-and-conditions/terms-and-conditions.component').then(
            m => m.TermsAndConditionsComponent
          ),
      },
    ],
  },
  {
    path: 'terms-and-conditions-en',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@core/components/terms-and-conditions/terms-and-conditions-en.component').then(
            m => m.TermsAndConditionsEnComponent
          ),
      },
    ],
  },
  {
    path: 'payment',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('@core/components/payment/payment.component').then(m => m.PaymentComponent),
      },
    ],
  },
  {
    path: 'consents',
    canActivate: [HasAlreadyProvidedConsents],
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./auth/components/consents/consents.component').then((m) => m.ConsentsComponent),
      },
    ],

  },
  {
    path: 'app',
    redirectTo: '/',
    pathMatch: 'prefix',
  },
  {
    path: '',
    loadComponent: () => import('./core/components/layout/layout.component').then(m => m.LayoutComponent),
    canActivate: [AuthGuard, RedirectWithoutConsents],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [DisallowNavigationToNativaGuard, RedirectIfCampusGuard],
      },
      {
        path: 'management',
        canActivate: [HasNativaRoleGuard],
        loadChildren: () => import('./admin/management/management.module').then(m => m.ManagementModule),
      },
      {
        path: 'profile',
        loadComponent: () =>
          import('./core/components/user-profile/user-profile.component').then(m => m.UserProfileComponent),
      },
      {
        path: 'faq',
        loadComponent: () => import('./faq/faq.component').then(m => m.FaqComponent),
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: ':tenant',
        canActivate: [ExceptNativaRoleGuard],
        children: [
          {
            path: 'news',
            loadComponent: () => import('./shared/components/news/news.component').then(m => m.NewsComponent),
          },
          {
            path: 'news/detail/:newsId',
            loadComponent: () =>
              import('@shared/components/news/components/news-details/news-details.component').then(
                m => m.NewsDetailsComponent
              ),
          },
          {
            path: 'notifications',
            loadChildren: () =>
              import('./user-notifications/user-notifications.module').then(m => m.UserNotificationsModule),
          },
          {
            path: 'profile',
            loadChildren: () => import('./tenant-profile/tenant-profile.module').then(m => m.TenantProfileModule),
          },
          {
            path: 'subscription',
            loadChildren: () =>
              import('./tenant-subscriptions/tenant-subscriptions.module').then(m => m.TenantSubscriptionsModule),
          },
          {
            path: 'flows',
            loadChildren: () => import('./flows/flows.module').then(m => m.FlowsModule),
          },
          {
            path: 'users',
            loadChildren: () => import('./tenant-users/tenant-users.module').then(m => m.TenantUsersModule),
          },
          {
            path: 'archive',
            loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
          },
          {
            path: '',
            redirectTo: '/not-found',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
