import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, exhaustMap, map } from 'rxjs';
import { updateProfileSuccess } from '../actions/profile-update.actions';

@Injectable()
export class ProfileEffect {
  private actions = inject(Actions);
  private translateSvc = inject(TranslateService);


  onLangchange$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(updateProfileSuccess),
        map((action: any) => action.profile.lang),
        exhaustMap((lang: 'it' | 'en' | 'pt') => {
          this.translateSvc.use(lang);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
}
