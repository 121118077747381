import { Component } from '@angular/core';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-weak-password-dialog',
    template: `
    <section>
      <h2 class="text-center" mat-dialog-title>
        {{ 'weak-password-dialog.component.la-password-e-troppo' | translate }}
      </h2>
      <p mat-dialog-content>{{ 'weak-password-dialog.component.usa-password-piu-lun' | translate }}</p>
      <div class="d-flex justify-content-center" mat-dialog-actions>
        <button mat-raised-button color="primary" mat-dialog-close>Ok</button>
      </div>
    </section>
  `,
    styles: [],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class WeakPasswordDialogComponent {
  constructor() {}
}
