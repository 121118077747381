import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { firstValueFrom } from 'rxjs';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { selectLang } from './app/auth/state/selectors/auth.selectors';
import { SharedModule } from './app/shared/shared.module';
import { environment } from './environments/environment';

const maskConfig: Partial<IConfig> = {
  validation: true,
};

registerLocaleData(localeIt);

if (environment.production) {
  enableProdMode();
}

async function initializeLanguage(store: Store, translate: TranslateService) {
  const lang = await firstValueFrom(store.select(selectLang));
  console.log('🌍 Initializing app language to:', lang);
  translate.setDefaultLang(lang);
  return firstValueFrom(translate.use(lang));
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      AppRoutingModule,
      SharedModule,
      CoreModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
          deps: [HttpClient],
        }
      }),
      NgxMaskModule.forRoot(maskConfig),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true,
      })
    ),
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    provideAnimations(),
  ]
})
  .then(async appRef => {
    // Get instances of Store and TranslateService after bootstrapping
    const store = appRef.injector.get(Store);
    const translate = appRef.injector.get(TranslateService);

    // Initialize language
    await initializeLanguage(store, translate);
  })
  .catch(err => console.error(err));
