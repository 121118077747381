import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-permission-denied-dialog',
    template: `
    <section>
      <h3 class="text-center">{{ 'permission-denied-dialog.component.you-dont-have-enough' | translate }}</h3>
      <section class="d-flex justify-content-center mt-4">
        <button mat-raised-button color="accent" mat-dialog-close>Close</button>
      </section>
    </section>
  `,
    styles: [],
    imports: [MatButton, MatDialogClose, TranslateModule]
})
export class PermissionDeniedDialogComponent {
  constructor() {}
}
