import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, switchMap, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { logoutUser, logoutUserWithoutRedirecting } from '../actions/auth.actions';

@Injectable()
export class LogoutEffect {
  private actions = inject(Actions);
  private authSrv = inject(AuthService);
  private router = inject(Router);

  onLogoutWipeAccessTokenAndRedirectUserToSignin$ = createEffect(
    () =>
      // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
      this.actions.pipe(
        ofType(logoutUser),
        switchMap(() =>
          this.authSrv.logout().pipe(
            catchError(error => {
              console.log('error wiping out access_token cookie', error);
              this.gotoSignin();
              return EMPTY;
            })
          )
        ),
        tap(() => this.gotoSignin())
      ),
    { dispatch: false }
  );

  onLogoutWithoutRedirectionWipeAccessTokenAndRedirectUserToSignin$ = createEffect(
    () =>
      // eslint-disable-next-line @ngrx/prefer-effect-callback-in-block-statement
      this.actions.pipe(
        ofType(logoutUserWithoutRedirecting),
        switchMap(() =>
          this.authSrv.logout().pipe(
            catchError(error => {
              console.log('error wiping out access_token cookie', error);
              this.gotoSignin();
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  private gotoSignin() {
    if (!this.router.url.includes('signin')) {
      this.router.navigate(['signin']);
    }
  }
}
